// Dashboard
import Dashboard from "pages/Dashboard";

// MASTER
import { Proyek } from "pages/Master"

/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

export default [
  // Dashboard
  {
    exact: true,
    path: "/",
    page: Dashboard,
    hak: ["SUPA", "PRY"],
  },

  // MASTER 
  {
    exact: true,
    path: "/master/proyek",
    page: Proyek,
    hak: ["SUPA", "PRY_MAS_PRY"],
  },
];
