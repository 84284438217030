import {
  IoCashOutline,
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";

/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

export default [
  {
    //Dashboard
    text: "Dashboard",
    link: "/",
    type: "menu",
    exact: true,
    icon: <IoSpeedometerOutline />,
    hak: ["SUPA"],
  },
  {
    // Master
    text: "Master",
    type: "dropdown",
    icon: <IoServerOutline />,
    hak: ["SUPA", "PRY"],
    menu: [
      {
        text: "Proyek",
        link: "/master/proyek",
        hak: ["SUPA", "PRY_MAS_PRY"],
      },
    ],
  },
  {
    // Transaksi
    text: "Transaksi",
    type: "dropdown",
    icon: <IoCashOutline />,
    hak: ["SUPA", "PRY"],
    menu: [
      {
        text: "Coming Soon",
        link: "/soon",
        hak: ["SUPA"],
      },
    ],
  },
  {
    // Laporan
    text: "Laporan",
    type: "dropdown",
    icon: <IoDocumentTextOutline />,
    hak: ["SUPA"],
    menu: [
      {
        text: "Coming Soon",
        link: "/soon",
        hak: ["SUPA"],
      },
    ],
  },
];
