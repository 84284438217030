import React, { useEffect, useContext } from "react"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { Layout } from "components"
import NotFound from "pages/404"
import Login from "pages/Login"
import { AuthContext } from "utilities"
import Config from "config"


const MyRoutes = () => {
  const {state} = useContext(AuthContext)
  const {isAuthenticated, role} = state
  const {ROUTES} = Config

  useEffect(() => {}, [state])
  
  const AuthRoutes = ({ setNavbarTitle }) => (
    <Switch>
      {/* Routes */}
      {ROUTES.map((val, index) => 
        val.hak && 
        val.hak.some(hak => role.includes(hak)) && (
        <Route
          key={index}
          exact={val.exact}
          path={val.path}
        >
          <val.page setNavbarTitle={setNavbarTitle} />
        </Route>
      ))}


      {/* Login form */}
      <Route path="/login">
        <Redirect to="/" />
      </Route>

      {/* 404 not found page */}
      <Route path="*">
        <NotFound setNavbarTitle={setNavbarTitle} />
      </Route>
    </Switch>
  )

  // Menangani pages ketika belum terotentikasi
  const UnAuthRoutes = () => (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  )

  // Routing
  const Routes = () => {
    if (!isAuthenticated) {
      return <UnAuthRoutes />
    }

    return (
      <Layout>
        <AuthRoutes />
      </Layout>
    )
  }

  return (
    <BrowserRouter basename="/">
      <Routes />
    </BrowserRouter>
  )
}

export default MyRoutes
